import { useRef, useEffect, useCallback, useState } from 'react'

import { isIos } from '@utils'

import { type ICustomPictureProps } from './CustomPicture.types'

const CustomPicture = (props: ICustomPictureProps) => {
  const {
    id,
    fallbackUrl,
    placeholder,
    className,
    width,
    height,
    style = null,
    onClick,
    alt = '',
    loading = 'lazy',
    sources,
    errorSrc,
    onLoad = () => {},
    lgImageDPR = '1x',
    mdImageDPR = '1x'
  } = props

  const isSafariBrowser = isIos()

  const imgAttributes = {
    alt,
    loading,
    draggable: false
  }

  if (id) {
    imgAttributes.id = id
  }

  if (className) {
    imgAttributes.className = className
  }

  if (width) {
    imgAttributes.width = width
  }

  if (height) {
    imgAttributes.height = height
  }

  if (onClick) {
    imgAttributes.onClick = onClick
  }

  if (style) {
    imgAttributes.style = style
  }

  if (!isSafariBrowser) {
    imgAttributes.src = placeholder
  }

  if (imgAttributes.loading === 'eager') {
    imgAttributes.fetchpriority = 'high'
  }

  const imgRef = useRef(null)

  const [canLoad, setCanLoad] = useState(loading === 'eager' ? true : false)

  const loadImage = useCallback(() => {
    if (/complete|interactive/.test(document.readyState)) {
      onLoad(imgRef.current)

      setCanLoad(true)

      if (placeholder && imgRef.current) {
        if (fallbackUrl && !isSafariBrowser) {
          imgRef.current.src = fallbackUrl
        }
      }
    } else {
      window.addEventListener('load', loadImage)
    }
  }, [placeholder, sources])

  useEffect(() => {
    if (loading !== 'eager') {
      loadImage()
    }

    if (imgRef.current) {
      imgRef.current.onerror = () => {
        setCanLoad(false)

        imgRef.current.srcset = errorSrc
        imgRef.current.src = errorSrc
        imgRef.current.onerror = null
      }
    }

    return () => {
      window.removeEventListener('load', loadImage)

      if (imgRef.current) {
        imgRef.current.onerror = null
      }
    }
  }, [])

  return (
    <picture className="aspect-ratio-box">
      {canLoad && (
        <>
          {sources.webp && sources.webp.small && (
            <>
              <source
                srcSet={sources.webp.medium[mdImageDPR].cdnpath}
                media="(max-width: 768px)"
                type="image/webp"
              />

              <source
                srcSet={sources.webp.large[lgImageDPR].cdnpath}
                media="(min-width: 769px)"
                type="image/webp"
              />
            </>
          )}

          {sources.jpeg && sources.jpeg.small && (
            <>
              <source
                srcSet={sources.jpeg.medium['2x'].cdnpath}
                media="(max-width: 768px)"
                type="image/jpeg"
              />

              <source
                srcSet={sources.jpeg.large['3x'].cdnpath}
                media="(min-width: 769px)"
                type="image/jpeg"
              />
            </>
          )}
        </>
      )}

      <img ref={imgRef} {...imgAttributes} alt={imgAttributes.alt} />
    </picture>
  )
}

export default CustomPicture
