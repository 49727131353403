import path from 'path'

import config from '@services/config'

import {
  ELITE_PLACEHOLDER_IMG,
  GCP_STORAGE_URL,
  SLUG_DELIMETER,
  SLUG_REPLACEMENT_SYMBOL
} from './constants'

export const preprocessImageUrl = (
  item: string | {},
  size: 'large' | 'medium' | 'small' | 'xsmall' | 'xxsmall' = 'medium',
  extension: 'webp' | 'jpeg' = 'webp',
  purpose = null
) => {
  if (!item)
    return {
      srcset: ELITE_PLACEHOLDER_IMG,
      altText: ''
    }

  const result = item

  if (result instanceof Object) {
    const filtered = (result.images || []).filter(
      x => x.size === size && x.extension === extension
    )

    const srcset = filtered.map(imageObj => {
      const url = `${config.cdn.images}/${imageObj.path}`

      return `${url} ${imageObj.version}x`
    })

    if (purpose === 'meta' && srcset.length) {
      return { srcset: srcset[0].split(' ')[0] }
    }

    return {
      srcset,
      altText: result.altText
    }
  }

  return {
    srcset: result
  }
}

export const prepareCDNImageVersions = imageObject => {
  if (!imageObject) return []
  const extensions = imageObject.extensions || ['jpeg', 'webp']
  const sizes = imageObject.sizes || [
    'xxsmall',
    'xsmall',
    'small',
    'medium',
    'large'
  ]
  const versions = imageObject.versions || [1, 2, 3]
  const parsedPath = path.parse(imageObject.path || '')
  const imageVersionArray = extensions.reduce((acc, r) => {
    sizes.map(size => {
      versions.map(v => {
        const fnWithVersion =
          parsedPath.name.replace(/xxsmall-x1/g, `${size}-x${v}`) + '.' + r
        acc = (acc || []).concat({
          path: `${parsedPath.dir}/${fnWithVersion}`,
          version: v,
          size,
          filename: fnWithVersion,
          extension: r,
          // This is just a fallback / backup Url,
          // we are only using this if CDN url doesn’t exist in preprocessImageUrl
          fullpath: `${GCP_STORAGE_URL}/${parsedPath.dir}/${fnWithVersion}`
        })
      })
    })
    return acc
  }, [])
  return imageVersionArray
}

export const addLeadingZeros = (value: number) => {
  const stringValue = value.toString()

  while (stringValue.length < 2) {
    return `0${stringValue}`
  }

  return stringValue
}

export const getSlugUrl = (name?: string, key?: string) => {
  return `${(name || '')
    .toLowerCase()
    .replace(/ /g, SLUG_REPLACEMENT_SYMBOL)}${SLUG_DELIMETER}${key}`
}

export const months = [
  'null',
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
]

export const hashData = async (data: string) => {
  const encoder = new TextEncoder()
  const encodedData = encoder.encode(data)
  const hashedBuffer = await crypto.subtle.digest('SHA-256', encodedData)
  const hashArray = Array.from(new Uint8Array(hashedBuffer))

  return hashArray.map(h => h.toString(16).padStart(2, '0')).join('')
}

export const ordinalSuffixOf = i => {
  const j = i % 10,
    k = i % 100

  if (j == 1 && k != 11) {
    return i + 'st'
  }

  if (j == 2 && k != 12) {
    return i + 'nd'
  }

  if (j == 3 && k != 13) {
    return i + 'rd'
  }

  return i + 'th'
}

export const getLocalAssetFromCDN = (path: string) => {
  return `${config.cdn.assets || 'http://localhost:4500'}${path}`
}

export const prepareSocialProofNumText = (num = 0, isShortVariant = true) => {
  if (isShortVariant && num >= 10000000) {
    return Number(num).toString().substring(0, 2) + 'M+'
  }

  if (isShortVariant && num >= 10000) {
    return Number(num).toString().substring(0, 3) + 'K+'
  }

  return `${Number(num).toLocaleString('en-US')}+`
}

export const numericWithComma = (
  num: string | number = 0,
  fractionDigits = 2
) => {
  return Number(num).toLocaleString('en-GB', {
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits
  })
}
