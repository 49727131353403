import Image from 'next/image'

import { Carousel } from 'react-responsive-carousel'

import { Stack } from '@mui/material'

import { replaceImagePathTemplate } from '@helpers/competitions/replace-image-path-template'

import Title from '../Title'

import { type ITestimonialsProps } from './Testimonials.types'
import { StyledSingleTestimonial } from './Testimonials.styled'

const Testimonials = (props: ITestimonialsProps) => {
  const { testimonials = [] } = props

  if (testimonials.length === 0) {
    return null
  }

  return (
    <Stack direction="column" gap="16px" width="100%" component="section">
      <Title variant="h2">Testimonials</Title>

      <div className="row justify-content-center testimonial">
        <div className="col col-md-8">
          <Carousel
            autoPlay={testimonials.length > 1}
            showArrows={testimonials.length > 1}
            emulateTouch={true}
            interval={5000}
            showThumbs={false}
            stopOnHover={true}
            showIndicators={true}
            showStatus={false}
          >
            {testimonials.map((testimonial, i) => {
              const {
                winnerName,
                prizeName,
                winnerProfileCDNImage,
                winnerResponse
              } = testimonial

              return (
                <StyledSingleTestimonial key={`testimonial-key-${i}`}>
                  <Image
                    loading="lazy"
                    src={replaceImagePathTemplate(
                      winnerProfileCDNImage,
                      'medium',
                      'webp',
                      2
                    )}
                    width={600}
                    height={350}
                    alt={`${winnerName}-${prizeName}`}
                  />

                  <p>{winnerResponse}</p>

                  <div className="clients-info">
                    <h5>{winnerName}</h5>

                    <p>The Winner of {prizeName}</p>
                  </div>
                </StyledSingleTestimonial>
              )
            })}
          </Carousel>
        </div>
      </div>
    </Stack>
  )
}

export default Testimonials
