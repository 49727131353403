import { merge } from 'lodash-es'

import { replaceImagePathTemplate } from '@helpers/competitions/replace-image-path-template'

import { type CdnImage } from '@elitecompetitions/core-api'

import { type IOptions } from './CdnImage.types'

const defaultOptions: IOptions = {
  mobileSize: 'small',
  tabletSize: 'medium',
  desktopSize: 'large'
}

const breakpoints = [1024, 768, 320] as const

const breakpointsMap = {
  '320': 'mobileSize',
  '768': 'tabletSize',
  '1024': 'desktopSize'
} as const

export function getCdnSrcset(image: CdnImage, options: IOptions = {}) {
  const _options = merge({}, defaultOptions, options)

  const versions = image.versions

  return breakpoints.map(breakpoint => {
    const size =
      _options[
        breakpointsMap[breakpoint.toString()] as keyof typeof defaultOptions
      ]

    return {
      breakpoint,
      urls: versions.map(version => {
        return `${replaceImagePathTemplate(
          image,
          size,
          'webp',
          version
        )} ${version}x`
      })
    }
  })
}
