import { useMemo } from 'react'

import { MAXIMUM_ALLOWED_TICKETS_PER_COMPETITION_PURCHASE } from '@helpers/constants'

import { Box } from '@mui/material'

import { TicketIcon } from '../../icons'

import {
  StyledSlider,
  StyledSliderContainer,
  StyledSliderFlag
} from './LuckyDipSlider.styled'
import { type ILuckyDipSliderProps } from './LuckyDipSlider.types'

const LuckyDipSlider = (props: ILuckyDipSliderProps) => {
  const {
    value,
    setValue,
    discountZonesEnabled = false,
    hideSliderFlag = false,
    disabled = false,
    max = MAXIMUM_ALLOWED_TICKETS_PER_COMPETITION_PURCHASE,
    min = 1
  } = props

  const handleChange = event => {
    setValue(+event.target.value)
  }

  const percentage = useMemo(() => {
    return max === min ? 100 : Math.ceil(((value - min) / (max - min)) * 100)
  }, [max, min, value])

  const canShowSliderFlag = useMemo(() => {
    return !disabled && !hideSliderFlag
  }, [disabled, hideSliderFlag])

  return (
    <StyledSliderContainer percentage={percentage}>
      <StyledSlider
        type="range"
        min={min}
        max={max}
        step={1}
        discountZonesEnabled={discountZonesEnabled}
        value={value}
        onChange={handleChange}
        disabled={disabled}
        disabledThumb={disabled}
      />

      {canShowSliderFlag && (
        <StyledSliderFlag
          percentage={percentage}
          fontWeight={500}
          fontSize="10px"
          lineHeight="14px"
          fontFamily="Poppins"
        >
          <Box width="12px" height="12px">
            <TicketIcon viewBox="-1 1 24 24" />
          </Box>

          {value}
        </StyledSliderFlag>
      )}
    </StyledSliderContainer>
  )
}

export default LuckyDipSlider
