import { useRef, useEffect, useCallback, useState, useMemo } from 'react'

import config from '@services/config'

import { getLocalAssetFromCDN, prepareCDNImageVersions } from '@helpers'

import { isIos } from '@utils'

import { type IThumbnailPictureProps } from './ThumbnailPicture.types'

const _placeHolder = getLocalAssetFromCDN(
  '/assets/img/competition-default-image.webp'
)

const ThumbnailPicture = (props: IThumbnailPictureProps) => {
  const {
    id,
    fallbackUrl,
    placeholder = _placeHolder,
    className,
    width,
    height,
    style = null,
    onClick,
    alt = '',
    loading = 'lazy',
    cdnImagesObj,
    errorSrc = _placeHolder,
    onLoad = () => {},
    preferredSize = 'small',
    forcePlaceholder = false
  } = props

  const isSafariBrowser = isIos()

  const imgAttributes = {
    alt,
    loading,
    draggable: false
  }

  if (id) {
    imgAttributes.id = id
  }

  if (className) {
    imgAttributes.className = className
  }

  if (width) {
    imgAttributes.width = width
  }

  if (height) {
    imgAttributes.height = height
  }

  if (onClick) {
    imgAttributes.onClick = onClick
  }

  if (style) {
    imgAttributes.style = style
  }

  if (!isSafariBrowser || forcePlaceholder) {
    imgAttributes.src = placeholder
  }

  if (imgAttributes.loading === 'eager') {
    imgAttributes.fetchpriority = 'high'
  }

  const imgRef = useRef(null)

  const [canLoad, setCanLoad] = useState(loading === 'eager' ? true : false)

  const sources = useMemo(() => {
    let cdnArray = cdnImagesObj

    if (!Array.isArray(cdnImagesObj)) {
      cdnArray = prepareCDNImageVersions(cdnImagesObj)
    }

    return cdnArray.reduce(
      (acc, item) => {
        if (item.extension) {
          acc[item.extension][item.size] = acc[item.extension][item.size] || {}
          acc[item.extension][item.size][item.version + 'x'] = item
          acc[item.extension][item.size][item.version + 'x'].cdnpath =
            `${config.cdn.images}/${item.path}`
        }

        return acc
      },
      { webp: {}, jpeg: {} }
    )
  }, [cdnImagesObj])

  const loadImage = useCallback(() => {
    if (/complete|interactive/.test(document.readyState)) {
      onLoad(imgRef.current)

      setCanLoad(true)

      if (placeholder && imgRef.current) {
        // imgRef.current.srcset = `${sources.jpeg.small['1x'].cdnpath} 1x, ${sources.jpeg.medium['2x'].cdnpath} 2x, ${sources.jpeg.large['3x'].cdnpath} 3x`
        if (fallbackUrl && !isSafariBrowser) {
          imgRef.current.src = fallbackUrl
        }
      }
    } else {
      window.addEventListener('load', loadImage)
    }
  }, [placeholder, sources])

  useEffect(() => {
    if (loading !== 'eager') {
      loadImage()
    }

    if (imgRef.current) {
      imgRef.current.onerror = () => {
        setCanLoad(false)

        imgRef.current.srcset = errorSrc
        imgRef.current.src = errorSrc
        imgRef.current.onerror = null
      }
    }

    return () => {
      window.removeEventListener('load', loadImage)

      if (imgRef.current) {
        imgRef.current.onerror = null
      }
    }
  }, [])

  const getSources = (type = 'webp', size = 'small') => {
    if (sources[type] && sources[type][size]) {
      return (
        <>
          <source
            srcSet={sources[type][size]['3x'].cdnpath}
            media="(-webkit-min-device-pixel-ratio: 2.5),
                    (min--moz-device-pixel-ratio: 2.5),
                    (min-device-pixel-ratio: 2.5)"
            type={`image/${type}`}
          />

          <source
            srcSet={sources[type][size]['2x'].cdnpath}
            media="(-webkit-min-device-pixel-ratio: 1.5),
                    (min--moz-device-pixel-ratio: 1.5),
                    (min-device-pixel-ratio: 1.5)"
            type={`image/${type}`}
          />

          <source
            srcSet={sources[type][size]['1x'].cdnpath}
            media="(-webkit-min-device-pixel-ratio: 1),
                    (min--moz-device-pixel-ratio: 1),
                    (min-device-pixel-ratio: 1)"
            type={`image/${type}`}
          />
        </>
      )
    } else {
      return null
    }
  }

  return (
    <picture className="aspect-ratio-box">
      {canLoad && (
        <>
          {getSources('webp', preferredSize)}
          {getSources('jpeg', preferredSize)}
        </>
      )}

      <img
        ref={imgRef}
        // src={isSafariBrowser ? fallbackUrl : placeholder}
        {...imgAttributes}
        alt={imgAttributes.alt}
      />
    </picture>
  )
}

export default ThumbnailPicture
