import { css, styled, Typography, type TypographyProps } from '@mui/material'

export const StyledSliderFlag = styled(Typography, {
  shouldForwardProp: propName => propName !== 'percentage'
})<
  {
    percentage: number
  } & TypographyProps
>`
  display: flex;
  padding: 3px 4px;
  gap: 4px;
  background: var(--ThirdVariant);
  color: var(--SecondaryVariant);
  border-radius: 4px;
  position: absolute;
  margin-top: -55px;
  margin-left: 5px;
  align-items: center;
  justify-content: center;

  ${({ percentage }) => {
    return css`
      left: ${percentage}%;
      transform: translateX(calc(-${percentage / 2}% - 15px));
    `
  }}
`

export const StyledSliderContainer = styled('div', {
  shouldForwardProp: propName => propName !== 'percentage'
})<{ percentage: number }>`
  position: relative;
  width: 100%;

  ${({ percentage }) => {
    return css`
      --slider-value: ${percentage}%;
    `
  }}
`

export const StyledSlider = styled('input', {
  shouldForwardProp: propName =>
    propName !== 'disabledThumb' && propName !== 'discountZonesEnabled'
})<{
  discountZonesEnabled: boolean
  disabledThumb: boolean
}>`
  -webkit-appearance: none;
  width: 100%;
  border-radius: 5px;
  outline: none;
  background-color: transparent;
  display: inline-block;

  &::-webkit-slider-thumb {
    ${({ disabledThumb }) => {
      if (disabledThumb) {
        return css`
          background: radial-gradient(
            60% 60% at 30% 30%,
            #fbfbfb 0%,
            var(--Neutral5) 70%,
            var(--Neutral6) 100%
          );
        `
      }

      return css`
        background: radial-gradient(
          60% 60% at 30% 30%,
          #fbfbfb 0%,
          #4e7ff1 70%,
          var(--Secondary) 100%
        );
      `
    }}

    -webkit-appearance: none;
    appearance: none;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    box-shadow:
      0 2px 1px -1px #fbfbfb inset,
      1px -2px 2px -1px #fbfbfb inset;
    cursor: pointer;
  }

  &:disabled::-webkit-slider-thumb {
    cursor: not-allowed;
    opacity: 0.75;
  }

  &::-moz-range-thumb {
    ${({ disabledThumb }) => {
      if (disabledThumb) {
        return css`
          background: radial-gradient(
            60% 60% at 30% 30%,
            #fbfbfb 0%,
            var(--Neutral5) 70%,
            var(--Neutral6) 100%
          );
        `
      }

      return css`
        background: radial-gradient(
          60% 60% at 30% 30%,
          #fbfbfb 0%,
          #4e7ff1 70%,
          var(--Secondary) 100%
        );
      `
    }}

    width: 22px;
    height: 22px;
    border-radius: 50%;
    box-shadow:
      0 2px 1px -1px #fbfbfb inset,
      1px -2px 2px -1px #fbfbfb inset;
    cursor: pointer;
  }

  &::-moz-range-track {
    ${({ disabledThumb = false }) => {
      if (disabledThumb) {
        return css`
          background: linear-gradient(
            to right,
            var(--Neutral5),
            var(--Neutral6) calc(var(--slider-value) - 4%),
            var(--Neutral6) calc(var(--slider-value) - 1%),
            var(--Neutral7) var(--slider-value)
          );
        `
      }

      return css`
        background: linear-gradient(
          to right,
          rgb(78, 126, 241),
          rgb(56, 114, 255) calc(var(--slider-value) - 4%),
          rgb(19, 65, 180) calc(var(--slider-value) - 1%),
          rgb(7, 0, 76) var(--slider-value)
        );
      `
    }}
    height: 32px;
    border-radius: 50px;
    margin-top: 0px;
  }

  &::-webkit-slider-runnable-track {
    ${({ disabledThumb = false, discountZonesEnabled = false }) => {
      if (disabledThumb) {
        return css`
          background: linear-gradient(
            to right,
            var(--Neutral5),
            var(--Neutral6) calc(var(--slider-value) - 4%),
            var(--Neutral6) calc(var(--slider-value) - 1%),
            var(--Neutral7) var(--slider-value)
          );
        `
      }

      if (discountZonesEnabled) {
        return css`
          background: linear-gradient(
              to right,
              var(--Secondary),
              var(--Secondary) calc(var(--slider-value) - 5%),
              transparent var(--slider-value)
            ),
            linear-gradient(90deg, var(--Secondary) 0%, var(--Primary) 100%),
            var(--SecondaryVariant);
        `
      } else {
        return css`
          background: linear-gradient(
            to right,
            rgb(78, 126, 241),
            rgb(56, 114, 255) calc(var(--slider-value) - 4%),
            rgb(19, 65, 180) calc(var(--slider-value) - 1%),
            rgb(7, 0, 76) var(--slider-value)
          );
        `
      }
    }}
    box-shadow: -10px 0px 12.857px 0px rgba(0, 0, 0, 0.16) inset;
    height: 32px;
    border-radius: 50px;
    margin-top: 0px;
  }

  &::-moz-focus-outer {
    border: 0;
  }
`
