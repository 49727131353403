import { isNil } from 'lodash-es'

import { useCallback, useEffect } from 'react'

import { observer } from 'mobx-react'

import hotJarUserIdentificationEvent from '@helpers/hotJarLoad'

import { useStores } from '@utils'
import * as FBPixel from '@utils/FBPixel'
import * as SCPixel from '@utils/SCPixel'

import config, { NodeEnvTypeEnum } from '@services/config'

import Toaster from '../Toaster'

const hotJarScriptId = 'hot-jar-id'

const sdks = [
  {
    id: hotJarScriptId,
    type: 'html',
    after: 2000,
    isAvailable: config.hotJar.isAvailable,
    content: `
        (function(h, o, t, j, a, r) {
          h.hj = h.hj || function() {
              (h.hj.q = h.hj.q || []).push(arguments)
          };
          h._hjSettings = {
              hjid: ${config.hotJar.id},
              hjsv: ${config.hotJar.version}
          };
          a = o.getElementsByTagName('head')[0];
          r = o.createElement('script');
          r.async = 1;
          r.id = "${hotJarScriptId}";
          r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
          a.appendChild(r);
        })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
      `
  }
]

const LazyComponents = () => {
  const { authStore } = useStores()

  const { profile = null, isLoggedIn = false } = authStore

  const loadAnalyticsTrackers = useCallback(async () => {
    if (isNil(profile)) {
      return
    }

    const advancedMatching =
      await FBPixel.getFBPixelAdvancedMatchingObject(profile)

    FBPixel.init(advancedMatching)
    FBPixel.pageView()

    SCPixel.init(profile)
  }, [profile])

  const loadSDKs = useCallback(() => {
    if (config.node.env !== NodeEnvTypeEnum.PRODUCTION) {
      return
    }

    sdks.forEach(sdk => {
      if (document.getElementById(sdk.id) === null && sdk.isAvailable) {
        const script = document.createElement('script')

        script.id = sdk.id
        script.type = 'text/javascript'

        if (sdk.type === 'html') {
          script.innerHTML = sdk.content
        } else {
          script.crossOrigin = 'anonymous'
          script.src = sdk.content
        }

        setTimeout(() => {
          document.body.appendChild(script)

          if (sdk.id == hotJarScriptId) {
            const hotJarScript = document.getElementById(hotJarScriptId)

            hotJarScript.onload = () => {
              hotJarUserIdentificationEvent({
                isLoggedIn,
                profile
              })
            }
          }
        }, sdk.after || 3000)
      }
    })
  }, [isLoggedIn, profile])

  useEffect(() => {
    ;(async () => {
      if (document.readyState === 'complete') {
        await loadAnalyticsTrackers()
      } else {
        window.addEventListener('load', loadAnalyticsTrackers)
      }
    })()

    loadSDKs()

    return () => {
      window.removeEventListener('load', loadAnalyticsTrackers)
    }
  }, [loadSDKs, loadAnalyticsTrackers])

  return <Toaster />
}

export default observer(LazyComponents)
