import { defaultTo, isArray, isFunction, isObject } from 'lodash-es'

import * as Sentry from '@sentry/nextjs'

import { CurrencyTypeEnum } from '@enums'

import { isBrowser } from '@helpers/isBrowser'

import {
  type CompetitionBaseDto,
  type CompetitionDetailsDTO,
  type ReservedTicketDTO,
  type UserTicketDTO
} from '@elitecompetitions/core-api'

import { getAppPlatform } from '.'

const paqPush = <T>(params: T) => {
  if (!isBrowser()) {
    return
  }

  try {
    window._paq = window._paq || []

    if (!isArray(params)) {
      return
    }

    if (!isFunction(window._paq.push)) {
      return
    }

    if (params[0] === 'trackEvent') {
      params[1] =
        typeof params[1] === 'string' && params[1].trim() !== ''
          ? params[1].replace(/\s+/g, '_')
          : 'default_category'
      params[2] =
        typeof params[2] === 'string' && params[2].trim() !== ''
          ? params[2].replace(/\s+/g, '_')
          : 'default_action'

      if (
        params[3] &&
        typeof params[3] === 'object' &&
        !Array.isArray(params[3])
      ) {
        params[3] = Object.entries(params[3]).reduce(
          (newPayload, [key, value]) => {
            if (typeof key === 'string' && value) {
              newPayload[key.replace(/\s+/g, '_')] = value
            }
            return newPayload
          },
          {}
        )
        params[3] = JSON.stringify(params[3])
      }
    }

    // console.log('Matomo: ', params)

    window._paq.push(params)
  } catch (error) {
    Sentry.captureException(error)
  }
}

let cartItems = {}

export const matomo = {
  init: (uid: string, email: string) => {
    paqPush(['setUserId', uid])
  },

  setUid: (uid: string) => {
    try {
      if (uid) {
        paqPush(['setUserId', uid])
      }
    } catch (error) {
      Sentry.captureException(error)
    }
  },

  view_product: (item: CompetitionDetailsDTO) => {
    try {
      const price = item.ticketPrice
      const value = item.isOnSale ? item.saleTicketPrice : item.ticketPrice
      const productSKU = item.id
      const productName = item.name
      const categoryName = item.hasInstantPrizes
        ? 'insta-win'
        : 'regular-competition'
      const priceUpdated = item.isOnSale ? item.saleTicketPrice : price

      paqPush([
        'setEcommerceView',
        productSKU,
        productName,
        categoryName,
        priceUpdated
      ])

      paqPush(['trackPageView'])
    } catch (error) {
      Sentry.captureException(error)
    }
  },

  add_to_cart: (
    item: CompetitionBaseDto,
    {
      overRideValue = false,
      val = 0,
      quantity = 1
    }: Partial<{
      overRideValue: boolean
      val: number
      quantity: number
    }>
  ) => {
    try {
      const price = item.ticketPrice
      const value = overRideValue
        ? val
        : item.isOnSale
          ? item.saleTicketPrice
          : item.ticketPrice

      const productSKU = item.id
      const productName = item.name
      const categoryName = item.hasInstantPrizes
        ? 'insta-win'
        : 'regular-competition'

      if (cartItems[productSKU]) {
        cartItems[productSKU].quantity += quantity
      } else {
        cartItems[productSKU] = {
          name: productName,
          category: categoryName,
          price: price,
          quantity: quantity
        }
      }

      paqPush([
        'addEcommerceItem',
        productSKU,
        productName,
        categoryName,
        price,
        cartItems[productSKU].quantity
      ])

      paqPush([
        'trackEcommerceCartUpdate',
        value * cartItems[productSKU].quantity
      ])
    } catch (error) {
      console.error('utils.matomo.add_to_cart', error)
    }
  },

  remove_from_cart: (item: CompetitionBaseDto) => {
    try {
      const productSKU = item.id

      paqPush(['removeEcommerceItem', productSKU])
    } catch (error) {
      Sentry.captureException(error)
    }
  },

  begin_checkout: ({
    items,
    value,
    couponCode
  }: {
    items: Array<{
      competition: CompetitionBaseDto
      tickets: ReservedTicketDTO[]
    }>
    couponCode?: string
    value: number
  }) => {
    try {
      const cart_items = items.map(item => {
        const { competition, tickets } = item
        const { id, name, ticketPrice, isOnSale, saleTicketPrice } = competition

        return tickets.map(ticket => {
          return {
            item_id: id,
            item_name: name,
            price: ticketPrice,
            discount:
              ticketPrice -
              (ticket.recommendedPrice ||
                (isOnSale ? saleTicketPrice : ticketPrice))
          }
        })
      })

      paqPush([
        'trackEvent',
        'ecommerce',
        'begin_checkout',
        JSON.stringify({
          currency: CurrencyTypeEnum.GBP,
          platform: getAppPlatform(),
          value,
          coupon: defaultTo(couponCode, ''),
          items: cart_items
        })
      ])
    } catch (error) {
      Sentry.captureException(error)
    }
  },

  add_payment_info: ({
    items,
    value,
    couponCode = '',
    paymentMethod
  }: {
    items: {
      competition: CompetitionBaseDto
      tickets: ReservedTicketDTO[]
    }[]
    value: number
    couponCode: string
    paymentMethod: string
  }) => {
    try {
      const cart_items = items
        .map(item => {
          const { competition, tickets } = item
          const { id, name, ticketPrice, isOnSale, saleTicketPrice } =
            competition

          return tickets.map(ticket => {
            return {
              item_id: id,
              item_name: name,
              price: ticketPrice,
              discount:
                ticketPrice -
                (ticket.recommendedPrice ||
                  (isOnSale ? saleTicketPrice : ticketPrice))
            }
          })
        })
        .flat()

      paqPush([
        'trackEvent',
        'ecommerce',
        'add_payment_info',
        JSON.stringify({
          payment_type: paymentMethod,
          currency: CurrencyTypeEnum.GBP,
          platform: getAppPlatform(),
          value,
          coupon: couponCode ? couponCode : '',
          items: cart_items
        })
      ])
    } catch (error) {
      Sentry.captureException(error)
    }
  },

  purchase: ({
    uniqueTickets,
    value,
    transactionId
  }: {
    uniqueTickets: {
      competition: CompetitionBaseDto
      tickets: UserTicketDTO[]
    }[]
    value: number
    transactionId: string
  }) => {
    try {
      let items = {}

      uniqueTickets.forEach(item => {
        const { tickets, competition } = item

        const item_id = competition.id
        const item_name = competition.name
        const price = competition.ticketPrice

        tickets.forEach(() => {
          if (items[item_id]) {
            items[item_id].quantity += 1
          } else {
            items[item_id] = {
              name: item_name,
              price: price,
              quantity: 1
            }
          }
        })
      })

      for (let item_id in items) {
        const { name, price, quantity } = items[item_id]

        paqPush(['addEcommerceItem', item_id, name, '', price, quantity])
      }

      paqPush(['trackEcommerceOrder', transactionId, value])
    } catch (error) {
      Sentry.captureException(error)
    }
  },

  customEvent: (eventName, payload) => {
    try {
      payload = isObject(payload) ? JSON.stringify(payload) : payload

      paqPush(['trackEvent', 'default_category', eventName, payload])
    } catch (error) {
      Sentry.captureException(error)
    }
  }
}
