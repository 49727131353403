import { max } from 'lodash-es'

import { observable, action } from 'mobx'

import { DateTime } from 'luxon'

import {
  child,
  get,
  getDatabase,
  onChildChanged,
  ref,
  set
} from 'firebase/database'

import * as Sentry from '@sentry/nextjs'

import {
  type ReservedTicketDTO,
  type UserReservedTicketsForCompetitionDTO,
  TicketsService
} from '@elitecompetitions/core-api'

import config from '@services/config'

import { getFirebaseApp } from '@utils/Firebase'

import { authStoreFactory } from './Auth'

const registerUsersReservedTicketsListener = async (
  userId: number,
  changeTicketHandler: () => void
) => {
  const UserReservedTicketsRef = child(
    ref(getDatabase(getFirebaseApp())),
    `UsersReservedTickets/${userId}`
  )
  const snapshot = await get(UserReservedTicketsRef)

  if (!snapshot.exists()) {
    await set(UserReservedTicketsRef, { id: userId })
  }

  changeTicketHandler()

  const ticketsChangedUnsubscribe = onChildChanged(
    UserReservedTicketsRef,
    changeTicketHandler
  )

  return () => {
    ticketsChangedUnsubscribe()
  }
}

export class CartStore {
  isSyncingCart: boolean = false

  timerTickId: number | undefined

  cartClearTimerStepInMS = 1000

  lastSyncedAt = 0

  private unsubscribeReservedTicketsListener: (() => void) | undefined =
    undefined

  @observable cartTickets: ReservedTicketDTO[] = []

  @observable cartTicketsOverview: UserReservedTicketsForCompetitionDTO[] = []

  @observable timeRemainingInSec = config.cart.cleanupIntervalSeconds

  async attachUserReservedTicketListeners() {
    this.unsubscribeReservedTicketsListener =
      await registerUsersReservedTicketsListener(
        authStoreFactory().profile.id,
        async () => {
          try {
            this.isSyncingCart = true

            await this.syncAll()
          } catch (error) {
            Sentry.captureException(error)
          } finally {
            this.isSyncingCart = false
          }
        }
      )
  }

  async removeUserReservedTicketListeners() {
    this.unsubscribeReservedTicketsListener?.()
  }

  async syncAll() {
    const [cartTickets, { data: cartTicketsOverview = [] }] = await Promise.all(
      [
        TicketsService.getUserReservedTickets(),
        TicketsService.getUserReservedTicketsOverview()
      ]
    )

    this.setTicketsData({
      cartTickets,
      cartTicketsOverview
    })

    this.syncCartTimer(cartTickets)

    if (this.timerTickId) {
      clearInterval(this.timerTickId)
    }

    this.timerTickId = window.setInterval(() => {
      this.timerCountdown()
    }, this.cartClearTimerStepInMS)
  }

  syncCartTimer(cartTickets: ReservedTicketDTO[]) {
    if (cartTickets && cartTickets.length > 0) {
      const updatedAtTimeStamps = cartTickets.map(
        ({ reservedAt }) => reservedAt
      )
      const lastUpdatedTimeStamp = max(updatedAtTimeStamps)
      const now = DateTime.now()
      const passedTimeStampInSec = now
        .diff(DateTime.fromISO(lastUpdatedTimeStamp))
        .as('seconds')

      const remainingTimeStampInSec =
        config.cart.cleanupIntervalSeconds - passedTimeStampInSec

      this.setTimer(remainingTimeStampInSec)
      this.timerCountdown()
    }
  }

  @action
  timerCountdown() {
    if (this.timeRemainingInSec > 0) {
      this.setTimer(
        this.timeRemainingInSec - this.cartClearTimerStepInMS / 1000
      )
    }
  }

  @action
  setTimer(timeInSec) {
    this.timeRemainingInSec = timeInSec < 0 ? 0 : timeInSec
  }

  @action
  resetTimer() {
    this.timeRemainingInSec = config.cart.cleanupIntervalSeconds
  }

  @action
  setTicketsData({
    cartTickets = [],
    cartTicketsOverview = []
  }: {
    cartTickets: ReservedTicketDTO[]
    cartTicketsOverview: UserReservedTicketsForCompetitionDTO[]
  }) {
    this.lastSyncedAt = Date.now()

    this.cartTickets = cartTickets
    this.cartTicketsOverview = cartTicketsOverview
  }

  @action
  async removeFromCart(ticket: ReservedTicketDTO) {
    await TicketsService.clearCart({
      ticketId: ticket.ticketId
    })
  }

  @action
  successClearCart() {
    this.setTicketsData({
      cartTickets: [],
      cartTicketsOverview: []
    })
  }
}

let cartStore: CartStore

export function cartStoreFactory() {
  if (!process.browser) {
    cartStore = new CartStore()
  }
  if (process.browser && !cartStore) {
    cartStore = new CartStore()
  }

  return cartStore
}

export default cartStore
