import { isNil } from 'lodash-es'

import { type UserProfile } from '@elitecompetitions/core-api'

import { isBrowser } from '@helpers/isBrowser'

import { HotJarEventEnum } from '@enums'

import * as Sentry from '@sentry/nextjs'

const hotJarUserIdentificationEvent = ({
  isLoggedIn = false,
  profile = null
}: {
  isLoggedIn: boolean
  profile: UserProfile | null
}) => {
  if (isLoggedIn && !isNil(profile)) {
    try {
      const { email, userId } = profile

      if (isBrowser() && window.hj) {
        window.hj<
          HotJarEventEnum,
          {
            firebaseUid: string
          }
        >(HotJarEventEnum.IDENTIFY, email, {
          firebaseUid: userId
        })
      }
    } catch (error) {
      Sentry.captureException(error)
    }
  }
}

export default hotJarUserIdentificationEvent
