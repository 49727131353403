import axios, {
  type AxiosRequestConfig,
  type InternalAxiosRequestConfig
} from 'axios'

import { v4 as uuid } from 'uuid'

import * as Sentry from '@sentry/nextjs'

import config from '@services/config'

import { getAppPlatform, getCookies } from '@utils'

import { authStoreFactory } from '@store/Auth'

export const requestV2Interceptor = async (
  config: InternalAxiosRequestConfig
) => {
  const cookies = getCookies()

  if (cookies._fbp) {
    config.headers['fbp'] = cookies._fbp
  }

  config.headers['platform'] = getAppPlatform()
  config.headers['X-Request-Id'] = uuid()

  const accessToken = await authStoreFactory().getToken()

  if (accessToken) {
    config.headers['Authorization'] = `Bearer ${accessToken}`
  }

  return config
}

const requestV2 = async (
  url: string,
  method = 'GET',
  options: AxiosRequestConfig = {}
) => {
  try {
    const response = await axios.request({
      baseURL: config.api.v2,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      url,
      method,
      ...options
    })

    return response
  } catch (error) {
    Sentry.captureException(error)
  }
}

export default requestV2
