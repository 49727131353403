import { useCallback } from 'react'

import { Box, Stack } from '@mui/material'
import Grid2 from '@mui/material/Unstable_Grid2'

import CompetitionCard from '../CompetitionCard'
import InstaWinCompetitionsLogo from '../InstaWinCompetitionsLogo'

import { type IInstaWinCompetitionTilesGridProps } from './InstaWinCompetitionTilesGrid.types'
import { StyledTilesContainer } from './InstaWinCompetitionTilesGrid.styled'

const InstaWinCompetitionTilesGrid = (
  props: IInstaWinCompetitionTilesGridProps
) => {
  const {
    id,
    instaWinCompetitions = [],
    isPast = false,
    analytics = null
  } = props

  const renderCardItems = useCallback(() => {
    return instaWinCompetitions.map(instaWinCompetition => {
      return (
        <Grid2
          xs={12}
          sm={12}
          md={4}
          lg={4}
          key={`instant-win-card-${instaWinCompetition.id}`}
        >
          <CompetitionCard
            layoutMode="fixed"
            competition={instaWinCompetition}
            isPast={isPast}
            analytics={analytics}
          />
        </Grid2>
      )
    })
  }, [instaWinCompetitions, isPast, analytics])

  return (
    <StyledTilesContainer spacing={1} id={id} component="section">
      <Stack direction="column" gap="16px" width="100%">
        <InstaWinCompetitionsLogo />

        <Box>
          <Grid2 container spacing={2}>
            {renderCardItems()}
          </Grid2>
        </Box>
      </Stack>
    </StyledTilesContainer>
  )
}

export default InstaWinCompetitionTilesGrid
