import Lottie from 'lottie-react'

import { useCallback } from 'react'

import { useRouter } from 'next/router'

import { numericWithComma } from '@helpers'

import { Stack } from '@mui/material'

import { ColorModeTypeEnum } from '@enums'

import { yellowLoadingDots } from '../../lottie'

import Dialog from '../Dialog'
import DialogHeader from '../DialogHeader'
import Button from '../Button'
import Typography from '../Typography'

import { StyledLottieContainer } from './WalletModal.styled'
import { type IWalletModalProps } from './WalletModal.types'

const WalletModal = (props: IWalletModalProps) => {
  const {
    isOpen = false,
    walletAmount = 0,
    walletMoneyBackPercentage = 0,
    isWalletAmountLoading = false,
    onClose
  } = props

  const router = useRouter()

  const handleOpenTransactions = useCallback(async () => {
    onClose()

    await router.push('/my-account/wallet')
  }, [onClose, router])

  const renderBalanceContent = useCallback(() => {
    if (isWalletAmountLoading) {
      return (
        <StyledLottieContainer>
          <Lottie animationData={yellowLoadingDots} loop={true} />
        </StyledLottieContainer>
      )
    }

    return (
      <>
        <Typography
          fontVariant="heading-2"
          weight="semibold"
          color="var(--Neutral1)"
        >
          {`£${numericWithComma(walletAmount)}`}
        </Typography>

        <Typography
          fontVariant="body-1"
          weight="regular"
          color="var(--Neutral2)"
        >
          Available balance
        </Typography>
      </>
    )
  }, [isWalletAmountLoading, walletAmount])

  return (
    <Dialog isOpen={isOpen} mode={ColorModeTypeEnum.DARK} onClose={onClose}>
      <DialogHeader
        dialogTitle="My wallet"
        onClose={onClose}
        mode={ColorModeTypeEnum.DARK}
        paddingBottom="24px"
      />

      <Stack direction="column" gap="24px">
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          gap="8px"
          padding="8px 8px"
          bgcolor="var(--Neutral7)"
          borderRadius="8px"
          textAlign="center"
        >
          {renderBalanceContent()}
        </Stack>

        {walletMoneyBackPercentage > 0 && (
          <Stack direction="column" gap="8px" textAlign="center">
            <Typography
              fontVariant="heading-4"
              weight="semibold"
              color="var(--Neutral1)"
            >
              {`Get ${walletMoneyBackPercentage}% wallet credit on your purchase`}
            </Typography>

            <Typography
              fontVariant="body-1"
              weight="regular"
              color="var(--Neutral1)"
              maxWidth="400px"
            >
              {`${walletMoneyBackPercentage}% of the total amount will be added directly into your wallet credit for you to spend.`}
            </Typography>
          </Stack>
        )}

        <Stack direction="row" gap="8px" width="100%">
          <Button
            variant="secondary"
            size="large"
            type="button"
            width="100%"
            onClick={onClose}
          >
            Close
          </Button>

          <Button
            variant="primary"
            size="large"
            type="button"
            width="100%"
            onClick={handleOpenTransactions}
          >
            Transactions
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  )
}

export default WalletModal
