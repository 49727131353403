import { getAuth, signOut } from 'firebase/auth'

import nookies from 'nookies'

import { action, configure, observable } from 'mobx'

import * as Sentry from '@sentry/nextjs'

import * as FBPixel from '@utils/FBPixel'
import * as SCPixel from '@utils/SCPixel'
import { getFirebaseApp } from '@utils/Firebase'
import { setLogContext } from '@utils/errorReporting'
import {
  FlutterChannelMessageActionEnum,
  sendFlutterChannelMessage
} from '@utils/channels/flutterChannel'
import { getProfileFullName } from '@utils/get-profile-full-name'

import hotJarUserIdentificationEvent from '@helpers/hotJarLoad'
import { isBrowser } from '@helpers/isBrowser'

import {
  UsersService,
  type UserProfile,
  type ConversationDTO,
  type UserBillingAddressDTO
} from '@elitecompetitions/core-api'

import { cartStoreFactory } from './Cart'

configure({
  enforceActions: 'observed'
})

export class AuthStore {
  @observable isLoggedIn = false
  @observable hydrated = false
  @observable auth = {}
  @observable profile: UserProfile | null = null
  @observable authLoading = false
  @observable userId = ''
  @observable hasCookieConsented = true
  @observable walletAmount = 0
  @observable isWalletAmountLoading = false
  @observable conversation: ConversationDTO | null = null

  token: string | undefined = undefined
  refreshToken: string | undefined = undefined

  constructor() {
    if (typeof window === 'undefined') return

    this.hydrateStore()
  }

  hydrateStore() {
    getAuth(getFirebaseApp()).onIdTokenChanged(async user => {
      if (!user) {
        this.setToken(undefined)
        this.setRefreshToken(undefined)

        nookies.destroy(undefined, 'token')
        nookies.destroy(undefined, 'refreshToken')
      } else {
        const token = await user.getIdToken()

        this.setToken(token)
        this.setRefreshToken(user.refreshToken)

        nookies.set(undefined, 'token', token, {
          path: '/',
          maxAge: 30 * 24 * 60 * 60
        })
        nookies.set(undefined, 'refreshToken', user.refreshToken, {
          path: '/',
          maxAge: 30 * 24 * 60 * 60
        })
      }
    })
  }

  setToken(token: string | undefined) {
    this.token = token
  }

  setRefreshToken(refreshToken: string | undefined) {
    this.refreshToken = refreshToken
  }

  async getToken() {
    if (!isBrowser()) {
      return this.token
    }

    const firebaseToken =
      await getAuth(getFirebaseApp()).currentUser?.getIdToken()

    if (firebaseToken) {
      return firebaseToken
    }

    return this.token
  }

  async getRefreshToken() {
    if (this.refreshToken) {
      return this.refreshToken
    }

    return getAuth(getFirebaseApp()).currentUser?.refreshToken
  }

  @action
  setConversation(conversation: ConversationDTO | null) {
    this.conversation = conversation
  }

  @action
  setWalletAmount(amount) {
    this.walletAmount = amount
  }

  @action
  setWalletAmountLoading(state: boolean) {
    this.isWalletAmountLoading = state
  }

  @action
  setUserId(userId) {
    this.userId = userId
  }

  @action
  setLogin(login) {
    this.isLoggedIn = login
  }

  @action
  async setProfile(profile: UserProfile | null) {
    this.profile = profile

    const advancedMatching =
      await FBPixel.getFBPixelAdvancedMatchingObject(profile)
    FBPixel.init(advancedMatching)
    SCPixel.init(profile)

    Sentry.getGlobalScope().setUser(
      profile
        ? {
            id: profile.userId,
            username: getProfileFullName(profile),
            email: profile.email
          }
        : null
    )
    setLogContext(profile)
  }

  async fetchAndSetWalletBalance() {
    try {
      this.setWalletAmountLoading(true)
      const walletBalance = await UsersService.getWalletBalance()

      this.setWalletAmount(walletBalance.internal)
    } finally {
      this.setWalletAmountLoading(false)
    }
  }

  @action
  async login() {
    try {
      const userDetails = await UsersService.getProfile()

      this.setUserId(userDetails.userId)
      this.setLogin(true)

      sendFlutterChannelMessage<{ email: string }>({
        action: FlutterChannelMessageActionEnum.LOGIN,
        data: {
          email: userDetails.email
        }
      })

      await this.setProfile(userDetails)

      hotJarUserIdentificationEvent({
        isLoggedIn: true,
        profile: userDetails
      })

      await this.fetchAndSetWalletBalance()

      const cartStore = cartStoreFactory()

      await cartStore.attachUserReservedTicketListeners()
    } catch (error) {
      Sentry.captureException(error)
    }
  }

  @action
  async logout() {
    try {
      await signOut(getAuth(getFirebaseApp()))

      this.setUserId('')
      this.setProfile(null)
      this.setLogin(false)
      this.setConversation(null)

      nookies.destroy(undefined, 'token')
      nookies.destroy(undefined, 'refreshToken')

      sendFlutterChannelMessage({
        action: FlutterChannelMessageActionEnum.LOGOUT
      })

      const cartStore = cartStoreFactory()

      await cartStore.removeUserReservedTicketListeners()
    } catch (error) {
      Sentry.captureException(error)
    }
  }

  @action
  updateUserDetails = async (details: {
    billingAddresses: UserBillingAddressDTO[]
  }) => {
    try {
      const { updated = false } = await UsersService.updateProfile({
        requestBody: details
      })

      /**
       * In this particular case, updating the address list does not involve changing the user phone number,
       * so we can be confident that the updated flag will always be true.
       * However, if you need to add logic for updating the user phone number,
       * please describe the logic for phone number verification.
       */
      if (updated) {
        const userDetails = await UsersService.getProfile()

        await this.setProfile(userDetails)
      }
    } catch (error) {
      Sentry.captureException(error)
    }
  }
}

let authStore: AuthStore

export function authStoreFactory(initialStore?: AuthStore | null) {
  if (!process.browser) {
    authStore = new AuthStore()
  }
  if (process.browser && !authStore) {
    authStore = new AuthStore()

    if (initialStore?.token) {
      authStore.setToken(initialStore.token)
    }

    if (initialStore?.refreshToken) {
      authStore.setRefreshToken(initialStore.refreshToken)
    }

    if (initialStore?.profile) {
      authStore.setProfile(initialStore.profile)
      authStore.setUserId(initialStore.profile.userId)
      authStore.setLogin(true)

      authStore.fetchAndSetWalletBalance()
      cartStoreFactory().attachUserReservedTicketListeners()
    }
  }
  return authStore
}

export default authStore
