import { MenuItem, Stack } from '@mui/material'

import { ColorModeTypeEnum, PerPageItemsEnum } from '@enums'

import Pagination from '../Pagination'
import Select from '../Select'

import { type TablePaginationProps } from './TablePagination.types'

const perPageItems = [
  PerPageItemsEnum.VALUE_6,
  PerPageItemsEnum.VALUE_10,
  PerPageItemsEnum.VALUE_25,
  PerPageItemsEnum.VALUE_50,
  PerPageItemsEnum.VALUE_100
]

const TablePagination = (props: TablePaginationProps) => {
  const {
    currentPage,
    pageCount,
    perPageCount,
    isLoading = false,
    onPageChange,
    onPerPageChange
  } = props

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      width="100%"
      gap={{
        lg: '16px',
        md: '16px',
        sx: '8px',
        xs: '4px'
      }}
      alignItems="center"
      paddingTop="16px"
      paddingBottom="16px"
    >
      <Pagination
        page={currentPage}
        count={pageCount}
        onChange={onPageChange}
        disabled={isLoading}
      />

      <Select
        disabled={isLoading}
        mode={ColorModeTypeEnum.DARK}
        value={perPageCount}
        onChange={onPerPageChange}
      >
        {perPageItems.map(value => {
          return (
            <MenuItem key={value} value={value}>
              {value.toString()}
            </MenuItem>
          )
        })}
      </Select>
    </Stack>
  )
}

export default TablePagination
