import { useMemo } from 'react'

import { replaceImagePathTemplate } from '@helpers/competitions/replace-image-path-template'

import { getCdnSrcset } from './get-cdn-srcset'
import { type ICdnImageProps } from './CdnImage.types'

const CdnImage = (props: ICdnImageProps) => {
  const {
    image,
    fallbackSrc,
    mobile = 'small',
    tablet = 'medium',
    desktop = 'large',
    placeholder,
    ...restProps
  } = props

  const srcset = useMemo(() => {
    if (!image) {
      return []
    }

    return getCdnSrcset(image, {
      mobileSize: mobile,
      tabletSize: tablet,
      desktopSize: desktop
    })
  }, [image, mobile, tablet, desktop])

  const defaultSrc = useMemo(() => {
    if (!image) {
      return fallbackSrc
    }

    if (placeholder) {
      return placeholder
    }

    return replaceImagePathTemplate(image, 'medium', 'webp', 1)
  }, [image, placeholder, fallbackSrc])

  return (
    <picture>
      {srcset.map(({ breakpoint, urls }) => (
        <source
          key={breakpoint}
          media={`(min-width: ${breakpoint}px)`}
          srcSet={urls.join(', ')}
          type="image/webp"
        />
      ))}
      <img {...restProps} src={defaultSrc} />
    </picture>
  )
}

export default CdnImage
