import { css, Select, styled } from '@mui/material'

import { ColorModeTypeEnum } from '@enums'

import { type ISelectProps } from './Select.types'

export const StyledSelect = styled(Select, {
  shouldForwardProp: propName =>
    propName !== 'mode' && propName !== 'isError' && propName !== 'isSuccess'
})<ISelectProps>`
  padding: 0;
  max-height: 40px;
  height: 40px;
  border-radius: 8px;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;

  ${({
    mode = ColorModeTypeEnum.LIGHT,
    isError = false,
    isSuccess = false
  }) => {
    if (isSuccess) {
      return css`
        color: var(--Neutral1);
        background-color: rgba(66, 222, 181, 0.2);
      `
    }

    if (isError) {
      if (mode === ColorModeTypeEnum.LIGHT) {
        return css`
          color: var(--PrimaryVariant);
          background-color: rgba(192, 1, 0, 0.2);
        `
      }

      if (mode === ColorModeTypeEnum.DARK) {
        return css`
          color: var(--Neutral4);
          background-color: rgba(192, 1, 0, 0.2);
        `
      }
    }

    if (mode === ColorModeTypeEnum.LIGHT) {
      return css`
        color: var(--Neutral7);
        background-color: var(--Neutral2);

        @media (hover: hover) and (pointer: fine) {
          &:hover {
            background-color: rgba(56, 114, 255, 0.2);
          }
        }
      `
    }

    if (mode === ColorModeTypeEnum.DARK) {
      return css`
        color: var(--Neutral1);
        background-color: var(--Neutral7);

        @media (hover: hover) and (pointer: fine) {
          &:hover {
            background-color: var(--Neutral6);
          }
        }
      `
    }
  }}

  svg {
    color: var(--Neutral4);
  }

  fieldset {
    border: none;
  }

  input,
  textarea {
    padding: 10px 16px;
    border-radius: 8px;
    border-width: 1px;
    border-style: solid;
    border-color: transparent;

    &:-webkit-autofill,
    &:-webkit-autofill:focus {
      transition:
        background-color 0s 600000s,
        color 0s 600000s !important;
    }

    ${({ isError = false }) => {
      if (isError) {
        return css`
          border-color: var(--Primary);
        `
      }
    }}

    ${({
      mode = ColorModeTypeEnum.LIGHT,
      isError = false,
      isSuccess = false
    }) => {
      if (isSuccess) {
        return css`
          &:focus {
            border-color: transparent;
          }
        `
      }

      if (isError) {
        return css`
          &:focus {
            border-color: var(--Primary);
          }
        `
      }

      if (mode === ColorModeTypeEnum.DARK) {
        return css`
          &:focus {
            background-color: var(--Neutral8);
            border-color: var(--Neutral5);

            svg {
              color: var(--Neutral5);
            }
          }
        `
      }

      if (mode === ColorModeTypeEnum.LIGHT) {
        return css`
          &:focus {
            border-color: var(--Secondary);
          }
        `
      }
    }}
  }
`
