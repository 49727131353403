import { isNil } from 'lodash-es'

import * as Sentry from '@sentry/nextjs'

import config from '@services/config'

import { type UserProfile } from '@elitecompetitions/core-api'

export enum SCPixelEventEnum {
  INIT = 'init',
  TRACK = 'track'
}

export enum SCPixelEventTypeEnum {
  ADD_CART = 'ADD_CART',
  PAGE_VIEW = 'PAGE_VIEW',
  PURCHASE = 'PURCHASE',
  SIGN_UP = 'SIGN_UP',
  VIEW_CONTENT = 'VIEW_CONTENT',
  START_CHECKOUT = 'START_CHECKOUT'
}

export const init = (profile: UserProfile | null) => {
  if (!config.snapchat.isAvailable) {
    return
  }

  if (isNil(profile)) {
    return
  }

  const { email } = profile

  try {
    if (typeof window !== 'undefined' && typeof window.snaptr === 'function') {
      window.snaptr<
        SCPixelEventEnum,
        string,
        {
          user_email: string
        }
      >(SCPixelEventEnum.INIT, config.snapchat.id, {
        user_email: email
      })
    }
  } catch (error) {
    Sentry.captureException(error)
  }
}

export const pageView = () => {
  if (!config.snapchat.isAvailable) {
    return
  }

  try {
    if (typeof window !== 'undefined' && typeof window.snaptr === 'function') {
      window.snaptr<SCPixelEventEnum, SCPixelEventTypeEnum>(
        SCPixelEventEnum.TRACK,
        SCPixelEventTypeEnum.PAGE_VIEW
      )
    }
  } catch (error) {
    Sentry.captureException(error)
  }
}

export const track = <P = never>(
  eventType: SCPixelEventTypeEnum,
  params: P
) => {
  if (!config.snapchat.isAvailable) {
    return
  }

  try {
    if (typeof window !== 'undefined' && typeof window.snaptr === 'function') {
      window.snaptr<SCPixelEventEnum, SCPixelEventTypeEnum, P>(
        SCPixelEventEnum.TRACK,
        eventType,
        params
      )
    }
  } catch (error) {
    Sentry.captureException(error)
  }
}
