import Lottie from 'lottie-react'

import { Stack } from '@mui/material'

import { yellowLoadingDots } from '../../lottie'

import { type ILoaderProps } from './Loader.types'
import { StyledDialog, StyledLottieContainer } from './Loader.styled'

const Loader = (props: ILoaderProps) => {
  const { children, isOpen = false } = props

  return (
    <StyledDialog open={isOpen}>
      <Stack
        direction="column"
        justifyContent="space-between"
        alignItems="center"
      >
        <StyledLottieContainer>
          <Lottie animationData={yellowLoadingDots} loop={true} />
        </StyledLottieContainer>

        {children}
      </Stack>
    </StyledDialog>
  )
}

export default Loader
