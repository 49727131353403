import { MuiOtpInput } from 'mui-one-time-password-input'

import { ColorModeTypeEnum } from '@enums'

import { css, styled } from '@mui/material'

import { type OtpInputProps } from './OtpInput.types'

export const StyledMuiOtpInput = styled(MuiOtpInput, {
  shouldForwardProp: propName =>
    propName !== 'isError' && propName !== 'mode' && propName !== 'isSuccess'
})<OtpInputProps>`
  & .MuiInputBase-root {
    caret-color: var(--Primary);
    border-radius: 8px;
    border-width: 1px;
    border-style: solid;
    border-color: transparent;
    font-family: 'Poppins', sans-serif;
    font-size: 24px;
    line-height: 32px;
    font-weight: 600;
    padding-left: 0;

    ${({
      mode = ColorModeTypeEnum.LIGHT,
      isError = false,
      isSuccess = false
    }) => {
      if (isSuccess) {
        if (mode === ColorModeTypeEnum.LIGHT) {
          return css`
            color: var(--Neutral10);
            background-color: rgba(66, 222, 181, 0.2);
          `
        }

        if (mode === ColorModeTypeEnum.DARK) {
          return css`
            color: var(--Neutral1);
            background-color: rgba(66, 222, 181, 0.2);
          `
        }
      }

      if (isError) {
        if (mode === ColorModeTypeEnum.LIGHT) {
          return css`
            color: var(--PrimaryVariant);
            background-color: rgba(192, 1, 0, 0.2);
          `
        }

        if (mode === ColorModeTypeEnum.DARK) {
          return css`
            color: var(--Neutral4);
            background-color: rgba(192, 1, 0, 0.2);
          `
        }
      }

      if (mode === ColorModeTypeEnum.LIGHT) {
        return css`
          color: var(--Neutral7);
          background-color: var(--Neutral2);

          @media (hover: hover) and (pointer: fine) {
            &:hover {
              background-color: rgba(56, 114, 255, 0.2);
            }
          }
        `
      }

      if (mode === ColorModeTypeEnum.DARK) {
        return css`
          color: var(--Neutral1);
          background-color: var(--Neutral7);

          @media (hover: hover) and (pointer: fine) {
            &:hover {
              background-color: var(--Neutral6);
            }
          }
        `
      }
    }}

    fieldset {
      border: none;
    }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type='number'] {
      -moz-appearance: textfield;
    }

    input {
      border-radius: 8px;
      border-width: 1px;
      border-style: solid;
      border-color: transparent;

      &:-webkit-autofill,
      &:-webkit-autofill:focus {
        transition:
          background-color 0s 600000s,
          color 0s 600000s !important;
      }

      /**
       * @link https://gist.github.com/kiding/72721a0553fa93198ae2bb6eefaa3299
       */
      @keyframes blink_input_opacity_to_prevent_scrolling_when_focus {
        0% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }

      ${({ isSuccess = false }) => {
        if (isSuccess) {
          return css`
            border-color: rgba(7, 16, 14, 0.2);
          `
        }
      }}

      ${({ isError = false }) => {
        if (isError) {
          return css`
            border-color: var(--Primary);
          `
        }
      }}

      ${({
        mode = ColorModeTypeEnum.LIGHT,
        isError = false,
        isSuccess = false
      }) => {
        if (isSuccess) {
          return css`
            &:focus {
              border-color: rgba(7, 16, 14, 0.2);
              animation: blink_input_opacity_to_prevent_scrolling_when_focus
                0.01s;
            }
          `
        }

        if (isError) {
          return css`
            &:focus {
              border-color: var(--Primary);
              animation: blink_input_opacity_to_prevent_scrolling_when_focus
                0.01s;
            }
          `
        }

        if (mode === ColorModeTypeEnum.DARK) {
          return css`
            &:focus {
              background-color: var(--Neutral8);
              border-color: var(--Neutral5);
              animation: blink_input_opacity_to_prevent_scrolling_when_focus
                0.01s;
            }
          `
        }

        if (mode === ColorModeTypeEnum.LIGHT) {
          return css`
            &:focus {
              border-color: var(--Secondary);
              animation: blink_input_opacity_to_prevent_scrolling_when_focus
                0.01s;
            }
          `
        }
      }}
    }
  }
`
