import { isNil } from 'lodash-es'

import { useCallback, useState } from 'react'

import { Controller, useForm } from 'react-hook-form'

import { Box, Stack } from '@mui/material'

import { ColorModeTypeEnum } from '@enums'

import Button from '../Button'
import TextField from '../TextField'
import Typography from '../Typography'

import {
  StyledAmexIcon,
  StyledCard,
  StyledForm,
  StyledMastercardIcon,
  StyledVisaIcon
} from './SavedCard.styled'
import { type ISavedCardFields, type ISavedCardProps } from './SavedCard.types'

const SavedCard = (props: ISavedCardProps) => {
  const {
    data,
    handleCardClick,
    isLoading = false,
    isRemoveCard = false
  } = props

  const [showCvv, setShowCvv] = useState(false)

  const { control, handleSubmit, formState } = useForm<ISavedCardFields>()

  const handleOpenCvvForm = useCallback(() => {
    setShowCvv(true)
  }, [])

  const submit = handleSubmit(async ({ cvv }) => {
    const { sourceId } = data

    handleCardClick({
      sourceId,
      cvv
    })
  })

  const handleClickAway = () => {
    if (!isRemoveCard) {
      setShowCvv(!showCvv)
    }
  }

  const renderCardActionButton = useCallback(() => {
    const { sourceId } = data

    if (isRemoveCard) {
      return (
        <Button
          variant="secondary"
          type="button"
          size="small"
          onClick={event => {
            event.stopPropagation()

            handleCardClick({
              sourceId
            })
          }}
        >
          Remove this card
        </Button>
      )
    }

    if (showCvv) {
      return (
        <StyledForm
          autoComplete="off"
          onSubmit={async event => {
            event.preventDefault()

            await submit()
          }}
        >
          <Stack direction="row" gap="8px" width="100%">
            <Controller
              control={control}
              name="cvv"
              render={({ field: { value, onChange } }) => {
                return (
                  <Stack
                    direction="column"
                    alignItems="flex-start"
                    gap="4px"
                    width="100%"
                  >
                    <TextField
                      mode={ColorModeTypeEnum.DARK}
                      hiddenLabel
                      fullWidth
                      value={value}
                      onChange={onChange}
                      placeholder="CVV"
                      autoFocus
                      isLoading={isLoading}
                      isError={!isNil(formState.errors.cvv)}
                      onClick={event => {
                        event.stopPropagation()
                      }}
                      inputProps={{
                        type: 'search',
                        pattern: '\\d{3,4}',
                        autoComplete: 'off',
                        maxLength: 4,
                        minLength: 3,
                        required: true
                      }}
                    />

                    {!isNil(formState.errors.cvv) && (
                      <Typography
                        fontVariant="body-3"
                        weight="regular"
                        color="var(--Neutral1)"
                      >
                        {formState.errors.cvv?.message}
                      </Typography>
                    )}
                  </Stack>
                )
              }}
              rules={{
                required: 'CVV is required'
              }}
            />

            <Button
              type="submit"
              variant="primary"
              size="large"
              disabled={isLoading}
              onClick={event => {
                event.stopPropagation()
              }}
            >
              {isLoading ? 'Loading...' : 'Pay'}
            </Button>
          </Stack>
        </StyledForm>
      )
    }

    return (
      <Button
        variant="secondary"
        type="button"
        size="small"
        onClick={handleOpenCvvForm}
      >
        Use this card
      </Button>
    )
  }, [isRemoveCard, isLoading, showCvv, data, submit])

  const renderCardIcon = useCallback(() => {
    const { type } = data

    switch (type.toLowerCase()) {
      case 'visa':
        return <StyledVisaIcon viewBox="0 0 34 24" />

      case 'mastercard':
        return <StyledMastercardIcon viewBox="0 0 35 24" />

      case 'amex':
        return <StyledAmexIcon viewBox="0 0 35 24" />

      default:
        return null
    }
  }, [data])

  return (
    <StyledCard isCursorPointer={!isRemoveCard} onClick={handleClickAway}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
        height="100%"
        gap="16px"
      >
        <Box width="34px" maxWidth="35px" height="100%">
          {renderCardIcon()}
        </Box>

        <Stack
          direction="column"
          height="100%"
          width="100%"
          justifyContent="space-between"
          gap="8px"
        >
          <Typography
            fontVariant="heading-4"
            weight="medium"
            color="var(--Neutral1)"
          >
            {data.issuer} {data.cardType} <br /> Credit card ****{data.last4}
          </Typography>

          <Stack direction="column" alignItems="flex-start" gap="8px">
            <Typography
              fontVariant="body-2"
              weight="regular"
              color="var(--Neutral2)"
            >
              {data.name}
            </Typography>

            <Typography
              fontVariant="body-2"
              weight="regular"
              color="var(--Neutral2)"
            >
              {`Expires ${data.expiryMonth}/${data.expiryYear}`}
            </Typography>

            {renderCardActionButton()}
          </Stack>
        </Stack>
      </Stack>
    </StyledCard>
  )
}

export default SavedCard
