import { Stack, type StackProps, styled } from '@mui/material'

import Typography from '../Typography'

export const StyledTypography = styled(Typography)`
  color: var(--Neutral1);
`

export const StyledDigitTypography = styled(StyledTypography)`
  width: 2ch;
`

export const StyledTimeTextTypography = styled(StyledTypography)`
  padding-left: 2px;
`

export const StyledTimerContainer = styled('div')`
  display: flex;
  justify-content: center;
  border-radius: 8px;
  background: var(--Secondary);
  backdrop-filter: blur(34px);
  padding: 8px 24px;
  gap: 32px;
`

export const StyledTilesContainer = styled(Stack)<StackProps>`
  scroll-margin-top: 200px;
`
