import { useMemo } from 'react'

import { toJS } from 'mobx'

import { type CdnImage } from '@elitecompetitions/core-api'

import { getLocalAssetFromCDN, prepareCDNImageVersions } from '@helpers'
import { replaceImagePathTemplate } from '@helpers/competitions/replace-image-path-template'

import config from '@services/config'

import CustomImage from '../CustomImage'
import CustomPicture from '../CustomPicture'
import CompetitionImageForModal from '../CompetitionImageForModal'

import { type ICompetitionImageProps } from './CompetitionImage.types'

const _placeholder = getLocalAssetFromCDN(
  '/assets/img/competition-default-image.webp'
)

const CompetitionImage = (props: ICompetitionImageProps) => {
  const {
    loading,
    imageUrl,
    cdnImages = [],
    cdnImage,
    alt,
    placeholder,
    width,
    height,
    style,
    className,
    onLoad = () => {},
    onClick,
    lgImageDPR = '1x',
    mdImageDPR = '1x',
    isModal = false
  } = props

  const cdnImagesPlainArray = useMemo(() => {
    const plain = toJS(cdnImages)

    if (!Array.isArray(plain)) {
      return prepareCDNImageVersions(cdnImages)
    }

    return plain
  }, [cdnImages])

  const sources = useMemo(() => {
    if (cdnImage) {
      const image = cdnImage as CdnImage
      const { extensions, sizes, versions } = image

      const sources = extensions.reduce((acc, extension) => {
        acc[extension] = sizes.reduce((acc, size) => {
          acc[size] = versions.reduce((acc, version) => {
            acc[version + 'x'] = {
              cdnpath: replaceImagePathTemplate(image, size, extension, version)
            }

            return acc
          }, {})
          return acc
        }, {})
        return acc
      }, {})

      return sources
    }

    return cdnImagesPlainArray.reduce(
      (acc, item) => {
        if (item.extension) {
          acc[item.extension][item.size] = acc[item.extension][item.size] || {}
          acc[item.extension][item.size][item.version + 'x'] = item
          acc[item.extension][item.size][
            item.version + 'x'
          ].cdnpath = `${config.cdn.images}/${item.path}`
        }
        return acc
      },
      { webp: {}, jpeg: {} }
    )
  }, [cdnImagesPlainArray, cdnImage])

  if (cdnImagesPlainArray.length === 0 && !cdnImage) {
    return (
      <CustomImage
        placeholder={placeholder || _placeholder}
        src={imageUrl || _placeholder}
        srcSet={imageUrl || _placeholder}
        alt={alt}
        loading={loading || 'lazy'}
        width={width}
        height={height}
        style={style}
        className={className}
        errorSrc={_placeholder}
        onLoad={onLoad}
        onClick={onClick}
      />
    )
  }

  return (
    <>
      {isModal ? (
        <CompetitionImageForModal
          fallbackUrl={imageUrl || _placeholder}
          className={className}
          style={style}
          alt={alt}
          loading={loading || 'lazy'}
          placeholder={placeholder || _placeholder}
          width={width}
          height={height}
          sources={sources}
          errorSrc={_placeholder}
          onLoad={onLoad}
        />
      ) : (
        <CustomPicture
          fallbackUrl={imageUrl || _placeholder}
          className={className}
          style={style}
          alt={alt}
          loading={loading || 'lazy'}
          placeholder={placeholder || _placeholder}
          width={width}
          height={height}
          sources={sources}
          errorSrc={_placeholder}
          onLoad={onLoad}
          lgImageDPR={lgImageDPR}
          mdImageDPR={mdImageDPR}
        />
      )}
    </>
  )
}

export default CompetitionImage
