import { css, styled } from '@mui/material'

import { IBadgeProps } from './Badge.types'

export const StyledBadge = styled('div', {
  shouldForwardProp: propName =>
    propName !== 'variant' &&
    propName !== 'layoutMode' &&
    propName !== 'borderRadius'
})<IBadgeProps>`
  display: inline-flex;
  align-items: center;
  flex-shrink: 0;

  ${({ borderRadius = '4px' }) => {
    return css`
      border-radius: ${borderRadius};
    `
  }}

  ${({ layoutMode = 'fixed' }) => {
    if (layoutMode === 'fixed') {
      return css`
        padding: 6px 12px;
      `
    }

    if (layoutMode === 'responsive') {
      return css`
        padding: 4px 8px;

        @media (min-width: 600px) {
          padding: 6px 12px;
        }
      `
    }
  }}

  ${({ variant = 'price' }) => {
    if (variant === 'price') {
      return css`
        background: var(--Secondary);
        color: var(--Neutral1);
      `
    }

    if (variant === 'endingSoon') {
      return css`
        background: var(--ThirdVariant);
        color: var(--SecondaryVariant);
      `
    }

    if (variant === 'freeToEnter') {
      return css`
        background: var(--Neutral1);
        color: var(--Neutral9);
      `
    }

    if (variant === 'appExclusive') {
      return css`
        background: var(--PrimaryVariant);
        color: var(--Neutral1);
      `
    }

    if (variant === 'upcoming') {
      return css`
        background: var(--Neutral1);
        color: var(--Primary);
      `
    }
  }}
`
